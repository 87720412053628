import * as React from 'react';
import { GoogleMap, LoadScript, InfoWindow, Marker } from '@react-google-maps/api';
import { useInView } from 'react-intersection-observer';

import './map.scss';

type TMapProps = {
  readonly center: {
    readonly lat: number;
    readonly lng: number;
  };
  readonly zoom: number;
  readonly apiKey: string;
  readonly title: string;
  readonly text: React.ReactNode;
  readonly ctaText: string;
  readonly href: string;
  readonly mapContainerClassName: string;
};

const options: google.maps.MapOptions = {
  minZoom: 6,
  maxZoom: 20,
  streetViewControl: false,
  mapTypeControl: false,
  styles: [
    {
      featureType: 'poi.business',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  backgroundColor: 'white',
};

const MapComponent: React.FunctionComponent<TMapProps> = ({
  center,
  zoom,
  title,
  text,
  ctaText,
  href,
  mapContainerClassName,
}: TMapProps) => {
  const [isInfoWindowOpen, setInfoWindowOpen] = React.useState(true);

  return (
    <GoogleMap
      center={center}
      zoom={zoom}
      clickableIcons={false}
      options={options}
      mapContainerClassName={mapContainerClassName}
    >
      {isInfoWindowOpen ? (
        <InfoWindow position={center} onCloseClick={() => setInfoWindowOpen(false)}>
          <div className="map_marker">
            <b>{title}</b>
            <div>{text}</div>
            <a href={href} target="_blank" rel="noreferrer">
              {ctaText}
            </a>
          </div>
        </InfoWindow>
      ) : (
        <Marker title={title} position={center} onClick={() => setInfoWindowOpen(true)} />
      )}
    </GoogleMap>
  );
};

const MapInner = React.memo(MapComponent);

export const Map: React.FunctionComponent<TMapProps> = (props) => {
  const [isLoaded, setLoaded] = React.useState(false);
  const { ref, inView } = useInView();

  if (inView && !isLoaded) {
    setLoaded(true);
  }

  return (
    <div className="visibility_checker" ref={ref}>
      <LoadScript googleMapsApiKey={props.apiKey}>{isLoaded && <MapInner {...props} />}</LoadScript>
    </div>
  );
};
